<template>
  <div class="welfare">
    <img src="../../../assets/imges/index/8.png" class="headerimg" />
    <div class="header">
      <span class="title1">加入我们</span><span class="arrows">></span>
      <span class="title2" @click="towelfare">薪酬福利</span>
      <span class="title2" @click="toPosition">员工招聘</span>
      <span class="title2 title2hover">人才发展</span>
    </div>
    <div class="centent" v-for="(item, index) in list" :key="index">
      <div class="cententinfo" v-if="index % 2 == 0">
        <div class="info">
          <div class="font">{{ item.theme }}</div>
          <div class="infotitle">{{ item.title }}</div>
          <div class="text">{{ item.desc }}</div>
        </div>
        <el-image :src="item.picture" class="cententinfoimg">
          <div slot="error" class="image-slot">
            <img src="../../../assets/imges/error/2.png" class="errimg" />
          </div>
        </el-image>
      </div>
      <div class="cententinfo2" v-else>
        <el-image :src="item.picture" class="cententinfoimg">
          <div slot="error" class="image-slot">
            <img src="../../../assets/imges/error/2.png" class="errimg" />
          </div>
        </el-image>
        <div class="info">
          <div class="font">{{ item.theme }}</div>
          <div class="infotitle">{{ item.title }}</div>
          <div class="text">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TalentList } from "../../../api/index.js";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    TalentList().then((res) => {
      this.list = res.data;
    });
  },
  methods: {
    toPosition() {
      this.$router.push("Position");
    },
    towelfare() {
      this.$router.push("welfare");
    },
  },
};
</script>

<style scoped lang="less">
.welfare {
  .header {
    width: 80%;
    margin: 0 auto;
    padding: 59px 0;
    font-size: 28px;
    border-bottom: 1px solid #979797;

    .title1 {
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
    }

    .arrows {
      color: #bebebe;
      padding-left: 25px;
    }

    .title2 {
      font-weight: 500;
      margin-left: 97px;
      color: #b4b4b4;
      cursor: pointer;
    }

    .title2hover {
      color: #2f7ffc;
      padding-bottom: 15px;
      border-bottom: 6px solid #2f7ffc;
    }
  }

  .cententinfo2,
  .cententinfo {
    padding-top: 103px;
    padding-bottom: 103px;
    display: flex;
    align-items: center;
    justify-content: center;

    .info {
      z-index: 2;
      width: 629px;
      height: 388px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: -3px 1px 23px 0px rgba(0, 0, 0, 0.13);
      border: 1px solid #e8e8e8;
      padding: 83px 63px;
      padding-right: 16px;
      .font {
        font-family: "You";
        font-size: 42px;
        color: #2f7ffc;
      }
      .infotitle {
        margin-top: 31px;
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
      }

      .text {
        font-size: 24px;
        font-weight: 500;
        color: #9b9c9d;
        line-height: 40px;
      }

      img {
        height: 31px;
      }
    }

    .cententinfoimg {
      width: 1000px;
      height: 583px;
      margin-left: -320px;
      border-radius: 15px;
    }
  }

  .cententinfo2 {
    background: #f5f5f9;

    .cententinfoimg {
      margin-left: 0;
    }

    .info {
      margin-left: -320px;
    }
  }
}
</style>
